import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../context/AppContext";
import Config from "../../Config";
import { Link } from "react-router-dom";
import User from "../../api/User";
import Jobs from "../../api/Jobs";
import AccountPageTitle from "./AccountPageTitle";
import ShowAlert from "../commons/ShowAlert";
import InputNumber from "../commons/InputNumber";
import Money from "../commons/Money";
import { AddAPhoto, CheckBox, Launch } from "@material-ui/icons";
import Select from "react-select";

const Account = () => {
  const [context, setContext] = useContext(AppContext);
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [userToEdit, setUserToEdit] = useState(user.username);
  const fileRef = useRef();

  const [formCountries, setFormCountries] = useState([]);
  const [formSkills, setFormSkills] = useState([]);
  const [formTypeOfJob, setFormTypeOfJob] = useState([]);
  const [formSeniorities, setFormSeniorities] = useState([]);

  useEffect(() => {
    setUser(context.user);
    setUserToEdit(context.user.username);
    Jobs.getMetadata().then((response) => {
      let skills = response.data.skills.map((skill) => {
        skill.value = skill.id;
        skill.label = skill.name;
        return skill;
      });
      let typesofjob = response.data.typesofjob.map((job) => {
        job.value = job.id;
        job.label = job.name;
        return job;
      });
      let seniorities = response.data.seniorities.map((seniority) => {
        seniority.value = seniority.id;
        seniority.label = seniority.name;
        return seniority;
      });
      let countries = response.data.countries.map((country) => {
        country.value = country.id;
        country.label = country.name;
        return country;
      });

      setFormSkills(skills);
      setFormTypeOfJob(typesofjob);
      setFormSeniorities(seniorities);
      setFormCountries(countries);
    });
  }, [context.user]);

  const handleSubmit = (e) => {
    setErrors("");
    setLoading(true);
    e.preventDefault();

    User.update(user, userToEdit).then((data) => {
      if (data.status == "error") {
        setErrors(data.message.error);
        ShowAlert.error(data.message.error);
      } else {
        setContext({ ...context, user });
        setUserToEdit(user.username);
        ShowAlert.success("Usuario editado");
      }
      setLoading(false);
    });
  };

  const updateInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const updateNewsletter = (e) => {
    setUser({ ...user, newsletter: e.target.checked });
  };

  const updateReactSelect = (name, selected) => {
    const allSelectedOptions = Array.isArray(selected)
      ? selected.map((currentValue) => currentValue.id).join(", ")
      : selected.id;
    setUser({ ...user, [name]: allSelectedOptions });
  };

  const updateAvatar = () => {
    fileRef.current.click();
  };

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setUser({
        ...user,
        avatar: reader.result,
      });
    };
    reader.onerror = function (error) {
      ShowAlert.error(error);
    };
  };

  const onChangeFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      getBase64(e.target.files[0]);
    }
  };

  return (
    <>
      <AccountPageTitle title="Perfil" />

      <div class="row pt-3 pb-2 mb-3 update-profile">
        <div class="col-md-6 offset-md-3">
          <div class="row mb-3">
            <div class="col-12 text-center mb-3">
              <div class="avatar mx-auto rounded-circle">
                <img src={user.avatar} />
              </div>
            </div>
            <div class="col-6 text-center">
              <button
                class="btn btn-secondary btn-block"
                onClick={updateAvatar}
                disabled={loading}
              >
                Cambiar foto
              </button>
              <br />
              <input
                type="file"
                class="d-none form-control"
                name="username"
                ref={fileRef}
                onChange={onChangeFile}
              />
            </div>
            <div class="col-6 text-center">
              <a
                href={Config.APP_URL + "/" + user.username}
                target="_blank"
                class="btn btn-block btn-outline"
              >
                Ver online <Launch fontSize="small" />
              </a>
            </div>
          </div>

          <form onSubmit={handleSubmit} method="POST">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Nombre</label>
                  <input
                    disabled={loading}
                    type="text"
                    class="form-control"
                    name="name"
                    value={user.name}
                    onChange={updateInput}
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Usuario</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        {Config.APP_URL.replace("http://", "") + "/"}
                      </span>
                    </div>
                    <input
                      disabled={loading}
                      type="text"
                      class="form-control"
                      name="username"
                      value={user.username}
                      onChange={updateInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    disabled={loading}
                    type="text"
                    class="form-control"
                    name="email"
                    value={user.email}
                    onChange={updateInput}
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>País</label>
                  {user && formCountries.length && (
                    <Select
                      options={formCountries}
                      className="react-select"
                      classNamePrefix="react-select"
                      defaultValue={formCountries.filter(
                        (country) => country.id == user.country_id
                      )}
                      onChange={(value) =>
                        updateReactSelect("country_id", value)
                      }
                    />
                  )}
                </div>
              </div>
            </div>

            <button
              type="submit"
              class="btn btn-primary btn-block"
              disabled={loading}
            >
              {loading ? "Guardando..." : "Guardar cambios"}
            </button>

            <div class="row mt-5">
              <div class="col-12">
                <h4>Deja que las empresas te encuentren</h4>
                <p>
                  Completa la siguiente información si estás en búsqueda laboral
                  o de posibles contratos de trabajo.
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Estado de tu búsqueda</label>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="available_to_work"
                      id="available_to_work1"
                      value="1"
                      onChange={updateInput}
                      checked={user.available_to_work == "1" ? true : false}
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Disponible
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="available_to_work"
                      id="available_to_work2"
                      value="0"
                      onChange={updateInput}
                      checked={user.available_to_work == "0" ? true : false}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      No disponible
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="newsletter"
                      id="newsletter"
                      onChange={updateNewsletter}
                      checked={user.newsletter}
                    />
                    <label class="form-check-label" for="newsletter">
                      Inscribirse a nuestra newsletter
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Titular</label>
                  <input
                    disabled={loading}
                    type="text"
                    class="form-control"
                    name="profile_title"
                    value={user.profile_title}
                    onChange={updateInput}
                  />
                  <small class="form-text text-muted">
                    Tip: Resume muy brevemente lo que puedes hacer como
                    programador/a
                  </small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>
                    Presentación{" "}
                    <span class="form-text text-danger">
                      <strong>No compartas</strong> datos de contacto ni tu
                      nombre completo aquí.
                    </span>
                  </label>
                  <textarea
                    disabled={loading}
                    class="form-control"
                    name="bio"
                    onChange={updateInput}
                    value={user.bio}
                    rows="12"
                  ></textarea>
                  <small class="form-text text-muted">
                    Tip: Comparte tus estudios, experiencia laboral, tus
                    habilidades únicas, cómo puedes ayudar a otros y cualquier
                    dato que consideres destacado en tu joven vida laboral ;)
                  </small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Conocimientos y aptitudes</label>
                  {user && formSkills.length && (
                    <Select
                      options={formSkills}
                      isMulti
                      className="react-select"
                      classNamePrefix="react-select"
                      name="skills"
                      defaultValue={
                        user.skills_ids &&
                        formSkills.filter((skill) =>
                          user.skills_ids
                            .split(",")
                            .map((str) => Number(str))
                            .includes(skill.value)
                        )
                      }
                      onChange={(value) =>
                        updateReactSelect("skills_ids", value)
                      }
                    />
                  )}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Modalidad de trabajo</label>
                  {user && formTypeOfJob.length && (
                    <Select
                      options={formTypeOfJob}
                      className="react-select"
                      classNamePrefix="react-select"
                      defaultValue={formTypeOfJob.filter(
                        (job) => job.id == user.type_of_job_id
                      )}
                      onChange={(value) =>
                        updateReactSelect("type_of_job_id", value)
                      }
                    />
                  )}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Experiencia</label>
                  {user && formSeniorities.length && (
                    <Select
                      options={formSeniorities}
                      className="react-select"
                      classNamePrefix="react-select"
                      defaultValue={formSeniorities.filter(
                        (senority) => senority.id == user.seniority_id
                      )}
                      onChange={(value) =>
                        updateReactSelect("seniority_id", value)
                      }
                    />
                  )}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Teléfono</label>
                  <input
                    disabled={loading}
                    type="text"
                    class="form-control"
                    name="phone_number"
                    value={user.phone_number}
                    onChange={updateInput}
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Sitio web</label>
                  <input
                    disabled={loading}
                    type="text"
                    class="form-control"
                    name="website_url"
                    value={user.website_url}
                    onChange={updateInput}
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Linkedin</label>
                  <input
                    disabled={loading}
                    type="text"
                    class="form-control"
                    name="linkedin_url"
                    value={user.linkedin_url}
                    onChange={updateInput}
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Github</label>
                  <input
                    disabled={loading}
                    type="text"
                    class="form-control"
                    name="github_url"
                    value={user.github_url}
                    onChange={updateInput}
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              class="btn btn-primary btn-block"
              disabled={loading}
            >
              {loading ? "Guardando..." : "Guardar cambios"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Account;
